export default [

    {
      path: '/',
      component: () => import('../components/Accueil.vue')
    },
    {
      path: '/agrume',
      component: () => import('../components/LandingAgrume.vue')
    },

    {
      path: '/four',
      component: () => import('../components/LandingFour.vue')
    },

    {
      path: '/rotisserie',
      component: () => import('../components/LandingRotisserie.vue')
    },
  
    {
      path: '/mention',
      component: () => import('../components/MentionLegales.vue')
    },
  ]