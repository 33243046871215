<template>
    <v-app>
    
        <v-sheet light class="">
            <v-toolbar fixed>
    
                <v-row class="d-flex justify-center">
    
                    <v-col cols="9" xl="4" lg="5" md="4">
                        <router-link to="/">
                            <v-toolbar-title>
                                <v-img class="logo" max-width="130" contain src="./assets/pulse-services-logo.svg"></v-img>
                            </v-toolbar-title>
                        </router-link>
                    </v-col>
    
    
                    <v-col cols="1" xl="4" lg="5" md="7" class=" d-flex align-center">
    
    
    
                        <template v-if="$route.path!='/'">
                                <v-btn plain @click.stop="drawer = !drawer" v-if="$vuetify.breakpoint.smAndDown">
                                    <v-icon left color="primary" large aria-hidden="true" class="notranslate mdi mdi-menu "></v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp" color="secondary">
                                    <v-btn plain class="text-capitalize">
                                        <router-link to="/">Accueil</router-link>
                                    </v-btn>
                                    <v-btn plain class="text-capitalize" @click="$vuetify.goTo('#produit')">Le produit</v-btn>
                                    <v-btn plain class="text-capitalize" @click="$vuetify.goTo('#fonction')">Fonctionnalités</v-btn>
                                    <v-btn plain class="text-capitalize" @click="$vuetify.goTo('#tarif')">Tarification</v-btn>
                                    <v-btn plain class="text-capitalize" @click="$vuetify.goTo('#avis')">Avis</v-btn>
                                    <v-btn plain class="text-capitalize" @click="$vuetify.goTo('#contact')">Contact</v-btn>
                                    <v-btn plain small>
                                        <a class="link" href="https://www.linkedin.com/company/pulseg/" rel="noopener" target="_blank">
                                            <v-icon>mdi-linkedin</v-icon>
                                        </a>
                                    </v-btn>
                                </v-toolbar-items>
                </template>


                <template v-else>
                    
                </template>

                         
                   
                            

                     
                    </v-col>
                </v-row>
    
    
    
    
            </v-toolbar>
    
            <v-navigation-drawer v-model="drawer" id="drawer" app v-if="$vuetify.breakpoint.smAndDown">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="text-h6 white--text">
                            <v-img class="logo" contain src="./assets/pulse-services-logo.svg"></v-img>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list>
                    <v-list-item>
                        <v-list-item-content>
                            <template v-if="$route.path!='/'">
                                <v-btn plain class="text-capitalize">
                                    <router-link to="/">Accueil</router-link>
                                </v-btn>
                                <v-btn plain class="text-capitalize" @click="$vuetify.goTo('#produit')">Le produit</v-btn>
                                <v-btn plain class="text-capitalize" @click="$vuetify.goTo('#fonction')">Fonctionnalités</v-btn>
                                <v-btn plain class="text-capitalize" @click="$vuetify.goTo('#tarif')">Tarification</v-btn>
                                <v-btn plain class="text-capitalize" @click="$vuetify.goTo('#avis')">Avis</v-btn>
                                <v-btn plain class="text-capitalize" @click="$vuetify.goTo('#contact')">Contact</v-btn>
                                <v-btn plain small>
                                    <a class="link" href="https://www.aidalinfo.fr" rel="noopener" target="_blank">
                                        <v-icon>mdi-linkedin</v-icon>
                                    </a>
                                </v-btn>
                            </template>

                            <template v-else>
                                
                            </template>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
        </v-sheet>
    
        <router-view></router-view>
    
    
        <v-footer elevation="5" padless color="white">
    
            <v-container fluid>
                <v-row class="d-flex py-5 justify-center align-center ">
                    <v-col cols="12" xl="4" lg="4" md="5" sm="5">
                        <router-link to="/">
                        <v-img contain width="180" height="80" src="./assets/pulse-services-logo.svg"></v-img>
                        </router-link>
                        <p class="pt-5 grey--text">PULSE Services est une marque de la société PULSE Group</p>
                        <p class="pt-5"> Made with <span style="color:red">❤</span> by <a href="https://www.aidalinfo.fr" rel="noopener" target="_blank">Aidalinfo 🚀</a></p>
                    </v-col>
                    <v-col cols="12" xl="2" lg="3" md="7" sm="3" class="">
                        <p class="text-xl-subtitle-1 text-lg-subtitle-1 text-md-subtitle-1 font-weight-bold ">Contactez nous !</p>
                        <p class="grey--text"> Pour en savoir plus sur nos machines et leurs options, <br class="hidden-xs"> écrivez-nous à :</p>
                        <a class="agrume--text font-weight-bold" href="mailto:contact@pulsegroup.fr">contact@pulsegroup.fr</a>
                        <p plain class="text-xl-subtitle-1 text-lg-subtitle-1 text-md-subtitle-1 ">
                                    <router-link to="/mention">Mention légales</router-link>
                        </p>
                    </v-col>
    
                </v-row>
            </v-container>
            <cookie-law class="justify-center">
                <div slot-scope="props" class="d-flex justify-center">
                    <p class="pt-2">
                    Ce site utilise des cookies pour vous offrir le meilleur service. En poursuivant votre navigation, vous acceptez l’utilisation des cookies.  <router-link to="/mention" class="pulseService--text">En savoir plus</router-link>
                    </p>
                    <v-btn class="skew mx-10 pulseService white--text" @click="props.accept"><span>Accepter</span></v-btn>

                </div>
            </cookie-law>
    
                <template v-if="$route.path!='/'">
                    <v-btn v-scroll="onScroll" fab dark fixed bottom right color="pulseService" @click="scrollTop">
                        <v-icon color="white">mdi-arrow-up</v-icon>
                    </v-btn>
                </template>
        </v-footer>
    
    </v-app>
</template>

<script>

import CookieLaw from 'vue-cookie-law';

export default {
    name: "App",

    components: { 
        CookieLaw,
    },
    data() {
        return {
            

            show: true,
            model: null,
            dialog: false,
            tab: null,
            drawer: null,
            items: [
                ["Le produit"],
                ["Fonctionnalité"],
                ["Tarification"],
                ["Avis"],
                ["Contact"],

            ] /** TODO: lien de menu-drawer  */ ,
            menuItems: [
                { title: 'Le produit', path: '/home' },
                { title: 'Fonctionnalités', path: '/fonction' },
                { title: 'Tarification', path: '/tarification' },
                { title: 'Avis', path: '/avis' },
                { title: 'Contact', path: '/contact' },
                { title: 'linkedin', path: '', icon: 'mdi-linkedin' }

            ],

            logos: [
                { src: require("./assets/logo_slider/carrefour.png") },
                { src: require("./assets/logo_slider/casino.png") },
                { src: require("./assets/logo_slider/franprix.png") },
                { src: require("./assets/logo_slider/vival.png") },
            ],

            testimoniales: [
                { src: require("./assets/avis/customer1.webp"), name: "Jonathan Doe", job: "Directeur du groupe Mamouth", text: "Je n'ai jamais bu un jus d'orange aussi juteux ! Et cette offre de service! Incroyable! ", },
                { src: require("./assets/avis/customer2.webp"), name: "Jessica Nulloy", job: "Hotesse de caisse chez Norma", text: "Mes clients ne craignent plus le coronavirus depuis qu'ils peuvent boire de délicieux jus de fruits.", },
                { src: require("./assets/avis/customer3.webp"), name: "Alex Dutillo", job: "DAF chez Prix Unique", text: "profile Je n'ai jamais bu un jus d'orange aussi juteux ! Et cette offre de service! Incroyable! ", },
                { src: require("./assets/avis/customer4.webp"), name: "Nicolas Brundo", job: "Directeur régional Intermarché", text: " J'ai implanté ces machines dans mes supermarchés, les clients l'adore.", },

            ],

            Croissant: [

                { text: 'Frais de livraison et de mise en service inclus', icon: 'mdi-checkbox-blank-circle-outline' },
                { text: 'Disponible sur vos événements courts', icon: 'mdi-checkbox-blank-circle-outline' },
                { text: 'Un matériel testé et fonctionnel à tous les coups', icon: 'mdi-checkbox-blank-circle-outline' },
                { text: 'Ne vous souciez plus du stockage ni de la logistique', icon: 'mdi-checkbox-blank-circle-outline' },
            ],

            Pain: [

                { text: 'Une fois que nos appareils ont terminé de vous rendre service, nous les auditons et reconditionnons dans nos ateliers.', icon: 'mdi-checkbox-blank-circle-outline' },
                { text: 'Bichonnés et remis en état, nos équipements retournent au travail et servent d autres clients dans le cadre de notre programme "Second Life".', icon: 'mdi-checkbox-blank-circle-outline' },
                { text: 'Après de nombreuses années de loyaux services chez nos clients, lorsqu il n est plus possible d assurer les exigences de qualité et de sécurité, nos équipements sont pris en charge pour leur recyclage dans une filière agréée mettant en avant les aspects sociaux et environnementaux.', icon: 'mdi-checkbox-blank-circle-outline' },

            ],

            slides: [

                { src: '@/assets/avis/customer4.webp', text: 'Libero eos officia, nostrum dolores ad obcaecati velit ullam fuga dolor placeat deleniti, autem beatae, recusandae quod ducimus qui excepturi magni ipsa?', title: 'Nicolas Brundo', subtitle: 'Directeur régional Intermarché' },
                { src: '@/assets/avis/customer4.webp', text: 'Libero eos officia, nostrum dolores ad obcaecati velit ullam fuga dolor placeat deleniti, autem beatae, recusandae quod ducimus qui excepturi magni ipsa?', title: 'Johnathan Doe', subtitle: 'Directeur général du groupe Mamouth' },
                { src: '@/assets/avis/customer4.webp', text: 'Libero eos officia, nostrum dolores ad obcaecati velit ullam fuga dolor placeat deleniti, autem beatae, recusandae quod ducimus qui excepturi magni ipsa?', title: 'Jessica Nulloy', subtitle: 'Hotesse de caisse chez Norma' },
                { src: '@/assets/avis/customer4.webp', text: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit.', title: 'Alex Duttilo', subtitle: 'DAF chez prisuniq' },
                { src: '@/assets/avis/customer4.webp', text: 'Libero eos officia, nostrum dolores ad obcaecati velit ullam fuga dolor placeat deleniti, autem beatae, recusandae quod ducimus qui excepturi magni ipsa?', title: 'Nicolas Brundo', subtitle: 'Directeur régional Intermarché' },

            ],

            colors: [

                'four',

            ],
        }
    },


    computed: {
        size() {
            const size = { xs: "x-small", sm: "small", lg: "large", xl: "x-large" }[
                this.$vuetify.breakpoint.name
            ];
            return size ? {
                [size]: true
            } : {};
        },

    },

    methods: {
        created: function() {
            setInterval(() => {
                this.show = !this.show
            }, 2000);
        },

        scrollTop: function() {
            this.intervalId = setInterval(() => {
                if (window.pageYOffset === 0) {
                    clearInterval(this.intervalId)
                }
                window.scroll(0, window.pageYOffset - 50)
            }, 20)
        },

        onScroll(e) {
            if (typeof window === 'undefined') return
            const top = window.pageYOffset || e.target.scrollTop || 0
            this.fab = top > 20
        },

        mounted: function() {
            window.addEventListener('scroll', this.onScroll)
        },
        beforeDestroy: function() {
            window.removeEventListener('scroll', this.onScroll)
        }
    },
};
</script>

<style scoped>
.logo {
    width: 50%;
}

.carrousel-logo {
    max-width: 10%;
}

a {
    text-decoration: none;
}

.contactez {
    background-image: url("./assets/img-agrume/image-bg.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

@media screen and (max-width:1024px) {
    .logo {
        width: 40%;
    }
}

@media screen and (max-width:810px) {
    .gala {
        width: 65%;
    }
    .carrousel-logo {
        max-width: 25%;
    }
}

@media screen and (max-width:425px) {
    .carrousel-logo {
        max-width: 25%;
    }
}
</style>
