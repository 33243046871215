import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({

   
      theme: {
        dark: false,
        themes: {
          light: {
            pulseService:"#c7414a",
            primary: "#455A64",
            secondary: "#F5F5F5",
            four:"#ffa000",
            agrume:"#ff5722",
            rotisserie:"#d32f2f",
            pizza:"",
            celluleDark: "#FFFFFF",
            backgroundGrey: "#f3f3f3",
          },
          dark: {
            pulseService:"#c7414a",
            primary: "#455A64",
            secondary: "#424242",
            four: "#ffa000",
            agrume:"#ff5722",
            rotisserie:"#d32f2f",
            pizza:"",
            celluleDark:"#212121",
            backgroundGrey: "#f3f3f3",
          },
        },
      },
});
